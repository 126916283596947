import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Container from "../components/container";
import Image from 'gatsby-image';

export default ({ data }) => {
  const post = data.markdownRemark;
  return (
    <Layout pageTitle={`${post.frontmatter.title} - Blog`}>
      <Container verticalPadding={false} extraClasses={"pv2 pv4-ns"} maxWidth={1200}>
        <Image className={"blog-image w-auto"} style={{"pointer-events": "none"}} fluid={post.frontmatter.featuredImage.childImageSharp.fluid}/>
        <h1 className="pt3 pb4">{post.frontmatter.title}</h1>
        <div className="f7 pb3 tracked ttu"><b className="pr2">{post.frontmatter.author}</b> {post.frontmatter.date}</div>
        <div className="blog-post" dangerouslySetInnerHTML={{ __html: post.html }}/>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        author
        date(formatString: "DD MMM YYYY")
        featuredImage {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
